<article id="main">
  <h1 class="d-none">Melofeel</h1>
  <img src="../assets/img/MeloFeeL LOGO4.svg" class="full_logo">

  <div id="main-buttons-container" class="d-flex flex-row flex-wrap justify-content-center">
    <button mat-raised-button class="btn btn-hover mr-3" (click)="scroll(presentation)">Découvrir</button>
    <button mat-raised-button class="btn btn-hover mr-3" (click)="scroll(contribute)">Contribuer</button>
    <button mat-raised-button class="btn btn-hover mr-3" (click)="scroll(values)">Valeurs</button>
    <button mat-raised-button class="btn btn-hover mr-3" (click)="scroll(motivations)">Motivations</button>
    <button mat-raised-button class="btn btn-hover btn-primary" (click)="openDemo()">DEMO</button>
  </div>
</article>
<a (click)="scroll(presentation)" >
  <div class="next">
    <img src="../assets/img/arrow_down2.svg">
  </div>
</a>

<article class="presentation" #presentation>
  <section class="w-100 mb-3 mb-md-0 d-flex align-items-center">
    <video class="d-none d-md-block" playsinline autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="../assets/videos/Vinyl.mp4" type="video/mp4">
    </video>
    <video class="d-block d-md-none" autoplay muted loop playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="../assets/videos/Vinyl_mobile.mp4" type="video/mp4">
    </video>

    <div class="d-flex flex-column flex-md-row justify-content-around align-items-center melofeel-section-header col-12 col-md-10 mb-4 mb-md-0">
      <div class="w-100 d-flex flex-column justify-content-between align-items-center">
        <h1 class="mt-4 mt-md-0 mb-4 mb-md-4">Découvrir</h1>
        <h2 class="mb-4 mb-md-0"><b>Pour une culture locale plus vivante</b></h2>
      </div>
      <img class="mw-100" src="../assets/img/test_bg_header.jpg">
    </div>
    <div class="d-flex w-100 flex-column flex-md-row justify-content-center align-items-center pt-md-5">
      <div class="d-none d-md-flex col-1"></div>
      <div class="col-12 col-md-5 first-section-first-paragraph">
        <div class="d-flex flex-column flex-md-row mb-3 align-items-center">
          <img class="discover-icons" src="../assets/img/icone_music.png">
          <ul>
            <li>Plateforme web et mobile pensée pour la musique.</li>
            <li>Tous les profils (musiciens, associations, salles, etc.).</li>
          </ul>
        </div>
        <div class="d-flex flex-column flex-md-row  mb-3 align-items-center">
          <img class="discover-icons" src="../assets/img/icone_local.png">
          <ul>
            <li>Gain de visibilité pour la culture locale.</li>
            <li>Priorité aux amateurs et artistes en voies de professionnalisation.</li>
          </ul>
        </div>
        <div class="d-flex flex-column flex-md-row mb-3 align-items-center">
          <img class="discover-icons" src="../assets/img/icone_efficiency.png">
          <ul>
            <li>Efficience de l'organisation, la communication et la gestion de son activité musicale.</li>
          </ul>
        </div>
      </div>
      <div class="d-none d-md-flex col-1"></div>
      <div class="col-12 col-md-5 d-flex flex-column align-items-start pt-3 pb-3" id="highlighted">
        <div class="d-flex flex-row justify-content-between col-12">
          <h3>1<sup>ère</sup> version</h3>
          <button mat-raised-button class="btn bg-white border btn-hover" (click)="openDemo()">DEMO</button>
        </div>
        <ul class="aligned-list">
          <li>Présentation du profil;</li>
          <li>Mise en relation;</li>
          <li>Messagerie;</li>
          <li>Recherches filtrées parmi les profils, événements.</li>
          <li>Communication;</li>
        </ul>
        <!-- <p>Vous pouvez accéder à <a href="https://demo.melofeel.com" target="_blank"><strong>la démo</strong></a>.</p> -->
        <p class="mt-3">La suite ? De nombreuses idées sont en réflexion. <a (click)="scroll(contribute)" style="color: white !important; text-decoration: underline !important"><strong>A vous de choisir !</strong></a></p>
      </div>
    </div>
  </section>

  <!-- <a (click)="scroll(contribute)" >
    <div class="next">
      <img src="../assets/img/arrow_down2.svg">
    </div>
  </a> -->

  <section class="w-100 mb-3 mb-md-0 d-flex align-items-center" #contribute>
    <video class="d-none d-md-block" playsinline autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="../assets/videos/piano.mp4" type="video/mp4">
    </video>
    <video class="d-block d-md-none"playsinline autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="../assets/videos/piano_mobile.mp4" type="video/mp4">
    </video>

    <div class="d-flex flex-column flex-md-row-reverse justify-content-around align-items-center contribute-section-header col-12 col-md-10 mb-4 mb-md-0">
      <div class="w-100 d-flex flex-column justify-content-between align-items-center">
        <h1 class="mt-4 mt-md-0 mb-4 mb-md-4">Contribuer</h1>
        <h2 class="mb-4 mb-md-0"><b>Vous avez la parole</b></h2>
      </div>
    </div>
    <div class="d-flex flex-column-reverse flex-md-row justify-content-around">
      <div class="col-12 col-md-3">
        <img class="w-100" src="../assets/img/illustration_tape.jpg">
      </div>
      <div class="col-12 col-md-6 d-flex flex-column justify-content-center second-section-content">
        <p>
          <b>J'ai besoin de vous,</b> pour créer une plateforme qui soit utile au plus grand nombre.<br/>
          La développer avec vos conseils et expériences garantirait qu’elle corresponde à <b>vos besoins</b>.
        </p>
        <p>
          Du musicien solo au gérant d'un bar concert, membre d'une association, amateur de musique, etc, votre avis m’intéresse.
        </p>
        <p>
          Mon objectif en créant MELOFEEL est de créer un espace utile pour le monde musical car pensé par et pour lui.
        </p>

        <div class="pb-4">
          <p>
            Pour participer :
          </p>
          <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()" class="d-flex flex-row justify-content-center align-items-center">
            <mat-form-field class="pr-4">
                <mat-label>Newsletter</mat-label>
                <input type="mail" matInput formControlName="mail" required>
                <div *ngIf="f.mail.invalid && (f.mail.dirty || f.mail.touched)">
                    <div *ngIf="f.mail.invalid && (f.mail.dirty || f.mail.touched)">
                        <mat-error *ngIf="f.mail.errors && f.mail.errors.email">Format non valide</mat-error>
                    </div>
                </div>
            </mat-form-field>
            <button mat-raised-button class="btn btn-primary" [disabled]="!newsletterForm.valid">Inscription</button>
          </form>
        </div>

        <p id="plaine-image" class="border rounded p-3 mb-3 mb-md-0">
          <a href="https://www.plaine-images.fr/" class="d-flex flex-column justify-content-center align-items-center">
            <img class="col-8 col-md-3" src="../assets/img/Logo_Plaine_Images_NOIR.png">
          </a>
          <span>Afin d'être accompagné et guidé, le projet MELOFEEL est en incubation au sein de la Plaine Images de Tourcoing.
          </span>
        </p>
      </div>
    </div>
  </section>

  <!-- <a (click)="scroll(values)" >
    <div class="next">
      <img src="../assets/img/arrow_down2.svg">
    </div>
  </a> -->

  <section class="w-100 mb-3 mt-3" #values>
    <video class="d-block d-md-none" playsinline autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="../assets/videos/mix_console_mobile.mp4" type="video/mp4">
    </video>
    <div class="d-flex flex-column flex-md-row justify-content-around">
      <div class="d-flex flex-column col-12 col-md-7">
        <div class="d-flex flex-column align-items-center values-header">
            <h1 class="mt-4 mt-md-0 mb-4 d-none d-md-block">Valeurs</h1>
            <div class="d-flex d-md-none justify-content-center w-100 mt-4 mt-md-0 mb-4 values-title">
              <h1>Valeurs</h1>
            </div>
            <h2 class="mb-4"><b>Les guides du projet</b></h2>
        </div>
          <div class="col-12 values-content mt-4">
            <p>
              Parce qu'elles animent ce projet, je vous partage les valeurs qui m'accompagnent :      
            </p>
            <ol>
              <li class="mb-3"><b>Créer un espace pour la musique amateur et en voie de professionalisation.</b><br> 
                Il n’y a pas que les artistes médiatisés qui méritent d’être écoutés.
              </li>
              <li class="mb-3"><b>Simplifier !</b><br>
                Il n’est pas nécessaire de passer des heures chaque jour pour s'organiser, être informé.
              </li>
              <li class="mb-3"><b>Mettre la musique locale en avant :</b><br>
                Les zéniths ne sont pas les seules salles de concert qui existent.
              </li>
              <li class="mb-3"><b>La coopération :</b><br>
                Indiquez-moi vos besoins, je m'efforce d’y trouver une solution.
              </li>
              <li class="mb-3"><b>Ouverture d'esprit :</b><br>
                La musique est un lieu d'expression et de partage, peu importe votre esthétique musicale ou votre profil, vous êtes les bienvenus.
              </li>
              <li class="mb-3"><b>Donner le choix :</b><br>
                Vous préférez la publicité ou prendre un abonnement ? Recevoir des notifications ou des mails ? C'est vous qui décidez.
              </li>
            </ol> 
          </div>
      </div>
      <div  class="d-none col-12 col-md-4 d-md-flex align-items-center values-video-div">
        <video playsinline autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
          <source src="../assets/videos/mix_console.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </section>

  <!-- <a (click)="scroll(motivations)" >
    <div class="next">
      <img src="../assets/img/arrow_down2.svg">
    </div>
  </a> -->

  <section class="w-100 mb-3 mt-3 d-flex align-items-center" #motivations>
    <video class="d-none d-md-block" playsinline autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="../assets/videos/concert.mp4" type="video/mp4">
    </video>
    <video class="d-block d-md-none" playsinline autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="../assets/videos/concert_mobile.mp4" type="video/mp4">
    </video>

    <div class="d-flex flex-column justify-content-center align-items-center motivations-section-header col-12 col-md-10 mb-4 mb-md-0">
      <div class="w-100 d-flex flex-column justify-content-between align-items-center">
        <h1 class="mt-4 mt-md-0 mb-4 mb-md-4">Motivations</h1>
        <h2 class="mb-2 mb-md-4 mt-0 mt-md-3 text-dark"><b>D'où vient MELOFEEL ?</b></h2>
      </div>
    </div>
    <div class="col-11 col-md-6 d-flex flex-column align-items-center border-right border-left" id="last-paragraph">
      <ol>
        <li class="pb-4">
          Suite aux difficultés que j'ai rencontrées lors de mes expériences musicales : disponibilités pour répéter, quand et où jouer, sous quelles conditions, comment se promouvoir, etc. Mais aussi le (trop ?) grand nombre d'outils différents pour répondre à chaque besoin.
        </li>
        <li class="pb-4">
          En tant que passionné, face au grand nombre d'informations et la quantité loupée.
        </li>
        <li>
          Car je pense qu’une partie du monde de la musique manque de <b>visibilité</b> et qu’il y a de nombreux talents qui ne sont pas découverts car noyés dans la masse. Comme pour notre alimentation, faisons évoluer nos habitudes et pensons <b>local</b> !
        </li>
      </ol>
    </div>
  </section>
</article>

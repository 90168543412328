import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private _snackbar: MatSnackBar) { }

  private _openSnackBar(message: string, action: string) {
    this._snackbar.open(message, 'X', {
      duration: 4000,
      panelClass: action,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }

  success(message: string): void {
    this._openSnackBar(message, 'success-snackbar');
  }

  error(message: string): void {
    this._openSnackBar(message, 'error-snackbar');
  }
}

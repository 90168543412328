import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from './services/snackbar/snackbar.service';
// import { MailerService } from './services/mailer.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  newsletterForm = new FormGroup({
    mail: new FormControl('', Validators.email),
  });

  constructor(protected https: HttpClient, private _snackbar: SnackbarService) {}

  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get f() {
    return this.newsletterForm.controls;
  }

  scroll(target: HTMLElement): void {
    target.scrollIntoView({ behavior: 'smooth'});
  }

  openDemo(): void {
    const link = document.createElement('a');
    link.href = 'https://demo.melofeel.com';
    link.target = '_blank';
    link.click()
  }

  onSubmit() {
      return this.https.post('https://api-preprod.melofeel.com/newsletters', {
        mail: this.newsletterForm.value.mail,
        createdAt: new Date(),
      }).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: res => { this._snackbar.success('Enregistrement effectué.');},
        error: err => { 
          if (err.error['hydra:description'] === 'mail: This value is already used.' ) {
            this._snackbar.error('Cette adresse est déjà enregistrée.');
          } else {
            this._snackbar.error('Une erreur est survenue, merci de réessayer ultérieurement.');
          }
        }
      });
  }
}

